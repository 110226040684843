import React, { useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { CustomWalletProvider } from './components/CustomWalletProvider';
import Header from './components/Header';
import Article_list_Header from './components/Article_list_Header';
import Footer from './components/Footer';
import ArticlePage from './pages/ArticlePage';
import ArticlesListPage from './pages/ArticlesListPage';
import { Helmet } from 'react-helmet';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';

function App() {
  const HELIUS_RPC = "https://mainnet.helius-rpc.com/?api-key=8aed2ce5-21f6-48e0-9b5f-86ca22b5a0bf";
  const endpoint = useMemo(() => HELIUS_RPC, []);
  const wallets = useMemo(() => [new PhantomWalletAdapter(), new SolflareWalletAdapter()], []);

  useEffect(() => {
    // Set favicon
    const faviconUrl = "https://pbs.twimg.com/profile_images/1659657965417160704/spa6XX5m_400x400.png";
    const setFavicon = (href) => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = href;
    };
    setFavicon(faviconUrl);
  }, []);

  return (
    <CustomWalletProvider>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Router>
              <div className="App">
                <Helmet>
                  <title>Pine Analytics</title>
                  <meta property="og:title" content="Pine Analytics" />
                  <meta property="og:description" content="Insights and analysis for the crypto ecosystem" />
                  <meta property="og:image" content="https://pbs.twimg.com/profile_images/1659657965417160704/spa6XX5m_400x400.png" />
                  <meta property="og:url" content="https://www.pineanalytics.xyz/" />
                  <meta property="og:type" content="website" />
                </Helmet>
                <Routes>
                  <Route path="/" element={<><Article_list_Header /><ArticlesListPage /></>} />
                  <Route path="/article_list" element={<><Article_list_Header /><ArticlesListPage /></>} />
                  <Route path="/article/:id" element={<><Header /><ArticlePage /></>} />
                </Routes>
                <Footer />
              </div>
            </Router>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </CustomWalletProvider>
  );
}

export default App;
    
