import React from 'react';

function Footer() {

    const socialIconsStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        maxWidth: '300px', // Adjust this value as needed
        margin: '0 auto',
      };
      
    const iconLinkStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px', // Adjust this value as needed
        height: '40px', // Adjust this value as needed
        margin: '0 5px', // Adds some horizontal spacing between icons
      };

    return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="article-sub-title">Quick Links</h3>
          <ul>
            <li><a target='_blank' href="https://app.sanctum.so/trade/SOL-pineSOL">Buy pineSOL</a></li>
            <li><a target='_blank' href="https://flipsidecrypto.xyz/teams/pine/dashboards">Flipside Team</a></li>
            <li><a target='_blank' href="https://forms.gle/VB2YGaixRQPCGFGT7">Partnership Form</a></li>
          </ul>
        </div>
        <div className="footer-section" style={{textAlign: 'center'}}>
          <h3 className="article-sub-title">Connect With Us</h3>
          <div className="social-icons" style={socialIconsStyle}>
            <a target='_blank' href="https://x.com/PineAnalytics" aria-label="X" style={iconLinkStyle}>
                <svg style={{width: '18px', height: '18px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" fill="currentColor"/>
                </svg>
            </a>
            <a target='_blank' href="https://substack.com/@pineanalytics1?utm_source=user-menu" aria-label="Substack" style={iconLinkStyle}>
                <svg style={{width: '16px', height: '16px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22.539 8.242H1.46V5.406h21.08v2.836zM1.46 10.812V24L12 18.11 22.54 24V10.812H1.46zM22.54 0H1.46v2.836h21.08V0z" fill="currentColor"/>
                </svg>
            </a>
            <a target='_blank' href="https://discord.gg/DdSu5yQMST" aria-label="Discord" style={iconLinkStyle}>
                <svg style={{width: '18px', height: '18px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.09 14.09 0 0 0 1.226-1.994.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z" fill="currentColor"/>
                </svg>
            </a>
            <a target='_blank' className="logo_foot" href="https://www.youtube.com/channel/UCiTRKJRZGfnwTPIsc4Wqbnw" aria-label="Youtube" style={iconLinkStyle}>
                <svg style={{width: '18px', height: '18px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" fill="currentColor"/>
                </svg>    
            </a>
          </div>
        </div>
        
      </div>
    </footer>
  );
}

export default Footer;