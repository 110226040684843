import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import articlesData from '../articles.json'; // Adjust the path as necessary

// ArticleRank component
const ArticleRank = ({ rank }) => {
  const getRankClass = (rank) => {
    switch(rank.toLowerCase()) {
      case 'free':
        return 'rank-free';
      case 'supporter':
        return 'rank-supporter';
      default:
        return 'rank-default';
    }
  };

  return (
    <span className={`article-rank ${getRankClass(rank)}`}>
      {rank || 'Unranked'}
    </span>
  );
};

function ArticlesListPage() {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [researchTier, setResearchTier] = useState('all');
  const [articleType, setArticleType] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 12;

  useEffect(() => {
    // Load and sort articles from newest to oldest
    const sortedArticles = articlesData.sort((a, b) => new Date(b.date) - new Date(a.date));
    setArticles(sortedArticles);
  }, []);

  useEffect(() => {
    console.log('Research Tier:', researchTier);
  }, [researchTier]);

  const filteredArticles = articles.filter(article => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    const title = article?.title?.toLowerCase() || '';
    const excerpt = article?.excerpt?.toLowerCase() || '';
    const rank = article?.rank || '';
    const type = article?.type || '';
  
    console.log('Article Rank:', rank);

    const matchesSearch = trimmedSearchTerm === '' || 
                          title.includes(trimmedSearchTerm) ||
                          excerpt.includes(trimmedSearchTerm);
    const matchesTier = researchTier.toLowerCase() === 'all' || 
                        rank.toLowerCase() === researchTier.toLowerCase();
    const matchesType = articleType.toLowerCase() === 'all' || 
                        type.toLowerCase() === articleType.toLowerCase();
    
    return matchesSearch && matchesTier && matchesType;
  });

  // Get current articles
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Pagination component
  const Pagination = () => {
    return (
      <div className="pagination" style={{ marginTop: 'auto', padding: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          style={{ marginRight: '10px', minWidth: '30px' }}
        >
          &lt;
        </button>
        <span className="pagination-current" style={{ margin: '0 10px' }}>
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
          style={{ marginLeft: '10px', minWidth: '30px' }}
        >
          &gt;
        </button>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} className="main-container">
      <aside className='sidebar'>
        <h5>Search Articles</h5>
        <div className="search-container">
          <input 
            type="text" 
            id="search-bar" 
            placeholder="Search articles..." 
            className="search-bar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <h5>Research Tier</h5>
        <select 
          id="research-tier" 
          className="research-tier-select"
          value={researchTier}
          onChange={(e) => setResearchTier(e.target.value)}
        >
          <option value="all">All</option>
          <option value="free">Free</option>
          <option value="supporter">Supporter</option>
        </select>
        <h5>Article Type</h5>
        <select 
          id="article-type" 
          className="research-tier-select"
          value={articleType}
          onChange={(e) => setArticleType(e.target.value)}
        >
          <option value="all">All</option>
          <option value="ecosystem overview">Ecosystem Overview</option>
          <option value="research">Research</option>
          <option value="token analysis">Token Analysis</option>
        </select>
      </aside>
      <main style={{
        minHeight: '700px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <section id="article-grid">
          {currentArticles.map(article => (
            <div style={{maxWidth: '350px'}} key={article.id} className="article-card">
              <Link to={`/article/${article.id}`}>
                <img style={{maxHeight: '175px', width: '100%'}} src={article.imageUrl} alt={article.title} />
                <div className="article-content">
                  <div className="article-header">
                    <span style={{ fontSize: '0.6rem' }}>{article.type || 'Unspecified Type'}</span>
                    <span style={{ fontSize: '0.6rem' }}>
                      {article.date ? new Date(article.date).toLocaleDateString() : 'No date'}
                    </span>
                  </div>
                  <h3 className="article-title1">{article.title || 'Untitled'}</h3>
                  <div className="article-meta"></div>
                  <div style={{marginTop: '5px'}} className="article-footer">
                    <span className="article-author">{article.author || 'Unknown Author'}</span>
                    <ArticleRank rank={article.rank || 'Unknown Rank'} />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </section>
        {totalPages > 0 && <Pagination />}
      </main>
    </div>
  );
}

export default ArticlesListPage;
