import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GateBox from '../components/GateBox';
import articlesData from '../articles.json';

function ArticlePage() {
  const [article, setArticle] = useState(null);
  const [showGateBox, setShowGateBox] = useState(false);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchArticle = () => {
      console.log('Fetching article with id:', id);
      console.log('Articles data:', articlesData);
      const foundArticle = articlesData.find(a => a.id.toString() === id);
      if (foundArticle) {
        console.log('Found article:', foundArticle);
        setArticle(foundArticle);
        setShowGateBox(foundArticle.rank === "Supporter");
      } else {
        console.error('Article not found');
      }
    };

    fetchArticle();
  }, [id]);

  const handleAccess = (balance) => {
    setUsdcBalance(balance);
    setShowGateBox(false);
  };

  const renderContent = () => {
    console.log('Rendering content:', article.content);
    if (typeof article.content === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: article.content }} />;
    } else if (Array.isArray(article.content)) {
      return article.content.map((item, index) => {
        switch (item.type) {
          case 'header':
            return <h1 key={index} className="article-title">{item.content}</h1>;
          case 'subtitle':
            return <h3 key={index} className="article-subtitle">{item.content}</h3>;
          case 'img':
            return <img key={index} src={item.content} alt="Article image" className="article-image" />;
          case 'text':
            return <p className="article_text"  dangerouslySetInnerHTML={{ __html: item.content }}></p>;
          case 'list':
            return (
              <ul key={index}>
                {item.content.map((listItem, listIndex) => (
                  <li className="article_text" key={listIndex} dangerouslySetInnerHTML={{ __html: listItem }} />
                ))}
              </ul>
            );
          default:
            console.log('Unknown content type:', item.type);
            return null;
        }
      });
    } else {
      console.error('Unsupported content format');
      return <p>Error: Unable to display article content.</p>;
    }
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  console.log('Rendering article:', article);

  return (
    <main className="a_main1" id="a_main1" style={{ backgroundColor: 'var(--test)'}}>
      <article style={{maxWidth: '610px', minHeight: '1000px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px'}}>
        <h1 className="article-title-big">{article.title}</h1>
        <div id="article-meta" className="article-meta-icon">
        <a 
    href={`https://twitter.com/${article.author_x}`} 
    target="_blank" 
    rel="noopener noreferrer"
    style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
  >
    <img 
      src={article.author_img} 
      alt="Author" 
      style={{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: '10px'
      }}
    />
    <span className='article-span-h' style={{ cursor: 'pointer' }}>{article.author}</span>
  </a>
  <span className='article-span-h' style={{marginLeft: "30px"}} id="article-date">
    {new Date(article.date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })}
  </span>
          
        </div>
        {showGateBox ? (
          <GateBox onAccess={handleAccess} />
        ) : (
          <div id="article-content" className="article-content-icon">
            {renderContent()}
          </div>
        )}
      </article>
    </main>
  );
}


export default ArticlePage;
