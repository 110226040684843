import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [theme, setTheme] = useState('dark-mode');

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark-mode' ? 'light-mode' : 'dark-mode');
  };

  const handleLogoClick = () => {
    window.location.href = "https://www.pineanalytics.xyz/";
  };

  return (
    <header>
      <nav>
        <Link to="https://app.pineanalytics.xyz/" className="back-button">← Articles</Link>
      </nav>
      
      <img 
        className="logo_1" 
        src="https://cdn.prod.website-files.com/66966924c7cebbd8799c6fad/66b139eca65f64e33d55a201_pinedao_2.png" 
        alt="Logo" 
        onClick={handleLogoClick}
        style={{ cursor: 'pointer' }}
      />
      <img 
        className="logo_2" 
        src="https://storage.googleapis.com/dash123/pine_shit/output-onlinepngtools.png" 
        alt="Logo" 
        onClick={handleLogoClick}
        style={{ cursor: 'pointer' }}
      />
      
      <button onClick={toggleTheme} className="theme-button" style={{marginTop: '6px'}}>
        {theme === 'dark-mode' ? (
          <svg id="sun-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="5"></circle>
            <line x1="12" y1="1" x2="12" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="23"></line>
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
            <line x1="1" y1="12" x2="3" y2="12"></line>
            <line x1="21" y1="12" x2="23" y2="12"></line>
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
          </svg>
        ) : (
          <svg id="moon-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        )}
      </button>
    </header>
  );
}

export default Header;
