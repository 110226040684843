import React, { useState } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from "@solana/spl-token";

const PINESOL_MINT = new PublicKey("SnKAf8aNjeYz8pY8itYn3VxFhT6Q8WNdPwy17s9USgC");
const BSOL_MINT = new PublicKey("bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1");

function GateBox({ onAccess }) {
  const { publicKey, signMessage } = useWallet();
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const signAndCheckBalance = async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!publicKey) throw new Error('Wallet not connected!');
      if (!signMessage) throw new Error('Wallet does not support message signing!');

      const message = new TextEncoder().encode('Sign to verify wallet ownership and check balances');
      await signMessage(message);

      const pinesolTokenAddress = await getAssociatedTokenAddress(PINESOL_MINT, publicKey);
      const bsolTokenAddress = await getAssociatedTokenAddress(BSOL_MINT, publicKey);
      
      let pinesolBalance = 0;
      let bsolBalance = 0;

      try {
        const pinesolAccountInfo = await getAccount(connection, pinesolTokenAddress);
        pinesolBalance = Number(pinesolAccountInfo.amount) / 1e9;
      } catch (error) {
        if (error.name !== 'TokenAccountNotFoundError') {
          throw error;
        }
      }

      try {
        const bsolAccountInfo = await getAccount(connection, bsolTokenAddress);
        bsolBalance = Number(bsolAccountInfo.amount) / 1e9;
      } catch (error) {
        if (error.name !== 'TokenAccountNotFoundError') {
          throw error;
        }
      }

      if (pinesolBalance > 1 || bsolBalance >= 50) {
        onAccess({ pinesolBalance, bsolBalance });
      } else {
        throw new Error('Insufficient balance. You need more than 1 pineSOL or 50 bSOL to access this content.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="gate-box">
      <div style={{ border: `1px solid var(--text-color)`, transition: 'box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out', backgroundColor: 'var(--bg-color)' }} className="login-box">
        <div>
          <h2 style={{ fontFamily: "'IBM Plex Mono', monospace"}}>Sign In</h2>
          <p style={{ fontFamily: "'IBM Plex Mono', monospace"}}>
            Connect your wallet, sign the message, and ensure you have more than 1 pineSOL or 50 bSOL to access this content. If you do not have pineSOL you can buy some <a target="_blank" style={{ color: `var(--glow-color)`}} href='https://app.sanctum.so/trade/SOL-pineSOL'>Here</a>
          </p>
          <div style={{ marginTop: '30px' }} className="button-container">
            <div className="wallet-button" style={{ width: '50%' }}>
              <WalletMultiButton style={{ backgroundColor: `var(--${isDarkMode ? 'dark' : 'light'}-bg)`, color: `var(--${isDarkMode ? 'dark' : 'light'}-text)`, height: '40px', fontFamily: "'IBM Plex Mono', monospace"}} id='submit_e' />
            </div>
            <div className="pl" style={{ width: '50%' }}>
              <button
                onClick={signAndCheckBalance}
                disabled={!publicKey || isLoading}
                className={`btn ${isLoading ? 'loading' : ''} ${!publicKey ? 'disabled' : ''}`}
                id='submit_e'
                style={{ 
                  height: '40px', 
                  marginTop: '1px', 
                  marginLeft: '37px',
                  opacity: !publicKey ? 0.5 : 1,
                  cursor: !publicKey ? 'not-allowed' : 'pointer',
                }}
              >
                {isLoading ? 'Verifying...' : 'Verify Balance'}
              </button>
            </div>
          </div>
          <div style={{ color: '#f0584d', marginTop: '30px' }}>
            {error && (
              <p className="error-message">
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .btn.disabled:hover {
          /* Remove any hover effects for disabled state */
          background-color: inherit;
          color: inherit;
          box-shadow: none;
        }
      `}</style>
    </div>
  );
}

export default GateBox;
